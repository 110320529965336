

























































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { deleteRepository } from '@/api';

export default Vue.extend({
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSort: 'name',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 5,
    };
  },
  computed: {
    ...mapGetters('repositories', ['repositories']),
    ...mapGetters('user', ['userPermissionGroups']),
  },
  async mounted() {
    await this.setRepositories();
  },
  methods: {
    ...mapActions('repositories', ['setRepositories', 'createNewRepository']),
    onDeleteRepositoryCTAClick(repositoryUuid: string) {
      this.$buefy.dialog.confirm({
        title: 'Deleting repository',
        message: 'Are you sure you want to <b>delete</b> this repository? This action cannot be undone.',
        confirmText: 'Delete Repository',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.$buefy.toast.open({ queue: true, message: `Deleting Repository...` });

          try {
            await deleteRepository(repositoryUuid);
            await this.setRepositories();
          } catch (error) {
            this.$buefy.toast.open({
              queue: true,
              type: 'is-danger',
              message: `An error occurred while deleting the repository. Try again later.`,
            });
          }
        },
      });
    },
    newRepositoryPrompt() {
      this.$buefy.dialog.prompt({
        message: 'Create a new repository 🚀',
        inputAttrs: {
          type: 'text',
          placeholder: 'Give a name to your new repository',
        },
        confirmText: 'Create',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: async (value, { close }) => {
          this.$buefy.toast.open({ queue: true, message: `We are creating your new repository...` });
          try {
            await this.createNewRepository({ name: value });
            this.$buefy.toast.open({ queue: true, type: 'is-success', message: `New repository created!` });
          } catch (error) {
            this.$buefy.toast.open({
              queue: true,
              type: 'is-danger',
              message: `An error occurred during the creation of your new repository. Please try again later.`,
            });
          }
          close();
        },
      });
    },
  },
});
