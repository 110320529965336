


















































































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import {
  postFileToRepository,
  putFileToS3SignedURL,
  getFileInformation,
  postUsersToRepository,
  deleteFileFromRepository,
} from '@/api';
import { FileToPost } from '@/types';

type RepositoryComponentData = {
  isPaginated: boolean;
  isPaginationSimple: boolean;
  isPaginationRounded: boolean;
  paginationPosition: string;
  defaultSort: string;
  defaultSortDirection: string;
  sortIcon: string;
  sortIconSize: string;
  currentPage: number;
  perPage: number;
  fileToUpload: FileToPost | null;
  filteredUsers: any[];
  selectedUsers: any[];
  repositoryUsers: any[];
  repositoryUsersTablePaginationCurrentPage: number;
};

export default Vue.extend({
  data(): RepositoryComponentData {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSort: 'name',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 5,
      fileToUpload: null,
      filteredUsers: [],
      selectedUsers: [],
      repositoryUsers: [],
      repositoryUsersTablePaginationCurrentPage: 1,
    };
  },
  computed: {
    ...mapGetters('repositories', ['currentRepository', 'currentRepositoryUsers']),
    ...mapGetters('users', ['userList']),
    ...mapGetters('user', ['userPermissionGroups', 'user']),
  },
  async mounted() {
    this.setCurrentRepository(this.$route.params.uuid);
    console.log('MOUNTED SET CURRENT REPO', this.currentRepository);
    await this.setUsers();
  },
  methods: {
    ...mapActions('repositories', ['setCurrentRepository', 'setRepositoryUsers', 'removeUsersFromRepository']),
    ...mapActions('users', ['setUsers']),
    deleteFileToUpload() {
      this.fileToUpload = null;
    },
    async deleteUser(userUuid: string) {
      const repositoryUuid = this.currentRepository.uuid;
      await this.removeUsersFromRepository({
        repositoryUuid,
        users: [{ uuid: userUuid }],
      });

      await this.setRepositoryUsers(this.currentRepository.uuid);
    },
    async deleteFile(fileUuid: string) {
      const repositoryUuid = this.currentRepository.uuid;
      await deleteFileFromRepository(repositoryUuid, fileUuid);
      await this.setCurrentRepository(repositoryUuid);
    },
    getFilteredTags(text: string) {
      this.filteredUsers = this.userList.filter((user: any) => {
        return user.mail.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    onDeleteFileCTAClick(fileUuid: string) {
      this.$buefy.dialog.confirm({
        title: 'Deleting file',
        message: 'Are you sure you want to <b>delete</b> this file? This action cannot be undone.',
        confirmText: 'Delete File',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.$buefy.toast.open({ queue: true, message: `Deleting File...` });

          try {
            await this.deleteFile(fileUuid);
          } catch (error) {
            this.$buefy.toast.open({
              queue: true,
              type: 'is-danger',
              message: `An error occurred while deleting the file. Try again later.`,
            });
          }
        },
      });
    },
    onLeaveRepositoryCTAClick() {
      this.$buefy.dialog.confirm({
        title: 'Leaving repository',
        message: 'Are you sure you want to <b>leave</b> this repository? This action cannot be undone.',
        confirmText: 'Leave Repository',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.$buefy.toast.open({ queue: true, message: `Leaving repository...` });

          try {
            await this.removeUsersFromRepository({
              repositoryUuid: this.currentRepository.uuid,
              users: [{ uuid: this.user.attributes.sub }],
            });

            this.$router.push({ name: 'repositories' });
          } catch (error) {
            this.$buefy.toast.open({
              queue: true,
              type: 'is-danger',
              message: `An error occurred while leaving the repository. Try again later.`,
            });
          }
        },
      });
    },
    async downloadFile(fileUuid: string) {
      const response = await getFileInformation(this.currentRepository.uuid, fileUuid);
      window.open(response.url);
    },
    async addUsersToRepository() {
      this.$buefy.toast.open({ queue: true, message: `Adding users to the current repository...` });
      try {
        await postUsersToRepository(this.currentRepository.uuid, this.selectedUsers);
        await this.setRepositoryUsers(this.currentRepository.uuid);
        this.$buefy.toast.open({ queue: true, type: 'is-success', message: `Users added to the repository!` });
        this.selectedUsers = [];
      } catch (error) {
        this.$buefy.toast.open({
          queue: true,
          type: 'is-danger',
          message: `Error adding users to the repository. Try again later.`,
        });
      }
    },
    showFileDescriptionPrompt() {
      this.$buefy.dialog.prompt({
        message: 'Add a description to your file 🚀',
        inputAttrs: {
          type: 'text',
          placeholder: 'A new shiny description...',
        },
        confirmText: 'Upload File',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: async (value, { close }) => {
          this.$buefy.toast.open({ queue: true, message: `We are uploading your file...` });

          try {
            const postResponse = await postFileToRepository(this.currentRepository.uuid, {
              name: this.fileToUpload?.name || '',
              description: value,
            });

            await putFileToS3SignedURL(postResponse.url, this.fileToUpload);
            await this.setCurrentRepository(this.$route.params.uuid);

            this.$buefy.toast.open({
              queue: true,
              type: 'is-success',
              message: `Your file has been uploaded successfully!`,
            });
          } catch (error) {
            this.$buefy.toast.open({
              queue: true,
              type: 'is-danger',
              message: `Error during file upload. Try again later.`,
            });
          }
          this.fileToUpload = null;
          close();
        },
      });
    },
  },
});
