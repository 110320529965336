




import Vue from 'vue';
import { API, Auth } from 'aws-amplify';
import { getRepositories } from '@/api';

export default Vue.extend({
  name: 'Protected',
  data() {
    return {
      user: {},
    };
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // @ts-ignore
        this.user = user;
      })
      .catch(() => {
        console.log('NON SEI LOGGATO!');
      });
  },
  mounted() {
    getRepositories();
    // console.log('REPO', test);

    const apiName = process.env.VUE_APP_AWS_API_NAME;
    const path = '/test';

    API.get(apiName, path, {})
      .then((response) => {
        console.log('RESPONSE API', response);
      })
      .catch((error) => {
        console.log('ERROR', error.response);
      });
  },
});
